@use '../../../sass/_partials/mixins';

.container {
  --max-width: 1000px;
  --padding: 1rem;
  width: min(var(--max-width), 100% - (var(--padding) * 2));
  margin-inline: auto;
}

.unevenColumns {
  @include mixins.minmq(1024px) {
    display: flex;
    align-items: stretch;
    gap: 20px;
    .titleCol {
      flex-basis: 45%;
    }

    .formCol {
      flex-basis: 55%;
    }
  }
}

.bg-waves-gradient {
  background-image: url(../../../../public/assets/images/bg-waves-gradient.png);
  background-size: cover;
  background-repeat: no-repeat;
}

