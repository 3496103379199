@tailwind base;

@layer base {
    :root {
        --clr-primary: hsl(197, 93%, 42%); //#0798D0;
        --clr-primary-200: #00aff3; //#00AFF3;

        /**
        --clr-primary: hsl(197, 97%, 56%); //#21C0FC;
        --clr-primary-light: #24e3f2; //#24E3F2;
        --clr-primary-200: #00aff3; //#00AFF3;
        --clr-primary-300: #0798d0; //#0798D0;
        --clr-portal-secondary: hsl(225, 40%, 18%); //#1C2541;
        --clr-base-text: #333333; //#333333;
        --clr-base-text-light: #575757; //#575757;
        --clr-card-light: #ebf5ff; //#EBF5FF;
        --clr-main-portal: #f3f5f7; //#F3F5F7;
        --clr-accent-1: hsl(180, 100%, 50%); //#00FFFF;
        --clr-border: hsl(213, 16%, 66%); //#9BA7B6;
        --clr-border-2: #707070; //#707070;

        --clr-warning-light: #fff8e7; // #FFF8E7;
        --clr-warning-dark: #d5b500; // #D5B500;

        --clr-accent-blue-light: #e6effc; // #E6EFFC;
        --clr-accent-blue-dark: #0764e6; // #0764E6;

        --clr-danger-light: #ffe5ee; // #FFE5EE;
        --clr-danger: #d00707; // #D00707;
        --clr-danger-dark: #aa0000; // #AA0000;

        */

        /* THEMING */
        
        --theme-clr-primary: 197deg 93% 42%; //#0798D0;
        --theme-clr-primary-200: 197deg 100% 48%; //#00AFF3;

        /**
        --theme-clr-primary: 197deg 97% 56%; //#22c0fc;
        --theme-clr-primary-light: 184deg 89% 55%; //#24E3F2;
        --theme-clr-primary-200: 197deg 100% 48%; //#00AFF3;
        --theme-clr-primary-300: 197deg 93% 42%; //#0798D0;
        --theme-clr-portal-secondary: 225deg 40% 18%; //#1C2541;
        --theme-clr-base-text: 0deg 0% 20%; //#333333;
        --theme-clr-base-text-light: 0deg 0% 34%; //#575757;
        --theme-clr-card-light: 210deg 100% 96%; //#EBF5FF;
        --theme-clr-main-portal: 210deg 20% 96%; //#F3F5F7;
        --theme-clr-accent-1: 180deg 100% 50%; //#00FFFF;
        --theme-clr-border: 213deg 16% 66%; //#9BA7B6;
        --theme-clr-border-2: 0deg 0% 44%; //#707070;

        --theme-clr-warning-light: 42deg 100% 95%; // #FFF8E7;
        --theme-clr-warning-dark: 51deg 100% 42%; // #D5B500;

        --theme-clr-accent-blue-light: 215deg 79% 95%; // #E6EFFC;
        --theme-clr-accent-blue-dark: 215deg 94% 46%; // #0764E6;

        --theme-clr-danger-light: 339deg 100% 95%; // #FFE5EE;
        --theme-clr-danger: 0deg 93% 42%; // #D00707;
        --theme-clr-danger-dark: 0deg 100% 33%; // #AA0000;

        */
    }
}
