@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://cdn.jsdelivr.net/gh/lykmapipo/themify-icons@0.1.2/css/themify-icons.css');

@import "./sass/main.scss";

body {
  /* font-family: 'Gotham' !important; */
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: #f2fbfe !important;
}

/* input::placeholder {
  font-family: 'Gotham' !important;
} */

/* wizard styles */
.wizard-card-footer {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}

.base-button {
  background-color: blue;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
  margin-right: 10px;
  margin-left: 10px;
  border-radius: 50px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease;
}

.base-button:hover {
  background-color: navy;
}

.base-button:focus {
  outline: none;
}

.base-button:active {
  transform: translateY(2px);
}

.finish-button {
  background-color: green;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
  margin-right: 10px;
  margin-left: 10px;
  border-radius: 50px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease;
}

.finish-button:hover {
  background-color: darkgreen;
}

.finish-button:focus {
  outline: none;
}

.finish-button:active {
  transform: translateY(2px);
}

.text_xxs {
  font-size: 12px;
  line-height: 0.5rem
}

.primary-scrollbar {

  /* width */
  &::-webkit-scrollbar {
    width: 3px;
    height: 5px
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #0798D0;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

body {
  &::-webkit-scrollbar {
    width: 10px !important;

  }
}